<template>
  <form
    class="drop-zone"
    :class="[isDragging?'drop-zone-over':'']"
    @dragover.prevent="isDragging=true"
    @dragenter.prevent="isDragging=true"
    @dragleave.prevent="isDragging=false"
    @drop.prevent="onChange">
    <label
      for="drop-zone">
      파일 선택을 위해 드래그 & 드롭 또는 클릭
    </label>
    <input
      id="drop-zone"
      type="file"
      @change="onChange">
    <v-overlay
      :value="loading === true"
      absolute>
      <v-progress-circular
        indeterminate
        color="primary">
      </v-progress-circular>
    </v-overlay>
  </form>
</template>

<script>
export default {
  name: 'DropZone',
  props: [
    'loading',
  ],
  data() {
    return {
      isDragging: false,
    };
  },
  methods: {
    onChange(event) {
      this.isDragging = false;
      const files = event.target.files || event.dataTransfer.files;
      this.$emit('change', files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-zone {
  margin: 1rem;
  position: relative;
  border: gray dotted 4px;
  line-height: 128px;
  &-over {
    background-color: #adcfff;
  }
  label {
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
  }
  input {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }
}
</style>
